<template>
  <v-app>
    <!---------------------------------- Main ---------------------------------- -->
    <v-main>
      <FlashMessage />
      <router-view />
    </v-main>

    <!--------------------------------- Footer --------------------------------- -->
    <!--
    <v-footer absolute>
      <small>&copy; 2013-2022 <a href="https://phonogram.co.jp" target="_blank">Phonogram</a> Inc.</small>
    </v-footer>
    -->

  </v-app>
</template>

<script>
import FlashMessage from '@/components/FlashMessage.vue'

export default {
  name: 'Auth',
  components: {
    FlashMessage
  }
}
</script>
<style scoped lang="scss">
  .theme--light{
    footer.v-footer{
      position: fixed;
      bottom: 0!important;
      background: transparent!important;
      *{
        color: white;
      }
    }
  }
</style>
